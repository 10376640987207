import React from 'react';

const ItemListSchema = ({ products }) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify(
        products
          .filter(({ priceRangeV2 }) => priceRangeV2?.maxVariantPrice?.amount !== '0.0')
          .map(({ title, handle, images, variants, vendor }) => ({
            '@context': 'http://schema.org/',
            '@type': 'Product',
            image: images?.length > 0 ? images[0]?.originalSrc : '',
            url: `https://www.activeskin.com.au/buy/${handle}`,
            name: title,
            sku: variants[0].sku,
            brand: {
              '@type': 'https://schema.org/Brand',
              name: vendor,
            },
            productID: variants[0].id,
            offers: {
              '@type': 'Offer',
              priceCurrency: 'AUD',
              price: variants[0].price,
              url: `https://www.activeskin.com.au/buy/${handle}`,
              availability: `https://schema.org/${
                variants[0]?.inventoryPolicy === 'CONTINUE' || variants[0]?.inventoryQuantity > 0
                  ? 'InStock'
                  : 'OutOfStock'
              }`,
              shippingDetails: {
                '@type': 'OfferShippingDetails',
                shippingRate: {
                  '@type': 'MonetaryAmount',
                  value: Number(variants[0].price) < 50 ? '4.95' : '0',
                  currency: 'AUD',
                },
                shippingDestination: {
                  '@type': 'DefinedRegion',
                  addressCountry: 'AU',
                },
                deliveryTime: {
                  '@type': 'ShippingDeliveryTime',
                  handlingTime: {
                    '@type': 'QuantitativeValue',
                    minValue: 0,
                    maxValue: 4,
                    unitCode: 'DAY',
                  },
                  transitTime: {
                    '@type': 'QuantitativeValue',
                    minValue: 1,
                    maxValue: 5,
                    unitCode: 'DAY',
                  },
                },
              },
            },
          }))
      )}
    </script>
  );
};

export default ItemListSchema;
